@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Kodchasan');

* {
  font-family: 'Kodchasan', sans-serif;
}

.disable-swipe {
  touch-action: none;
  overscroll-behavior: none;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
